import { Avatar, Chip } from "@mui/material";
import React from "react";
import EmpresaService from "../../services/EmpresaService";
import AutoCompleteAsyncPersist from "./autocomplete/AutoCompleteAsyncPersist";

const EmpresaComplete = (props) => {
  const handleFetchAPI = async (valueSearch) => {
    const response = await EmpresaService.pesquisa(valueSearch, 0);
    return response.content;
  };

  const renderTags = (tagValue, getTagProps) => {
    return tagValue.map((option, index) => (
      <Chip
        color="secondary"
        avatar={<Avatar alt={option.nome} src={option.logomarca} />}
        label={option.nome}
        {...getTagProps({ index })}
      />
    ));
  };

  return (
    <AutoCompleteAsyncPersist
      name="autoEmpresa"
      renderTags={renderTags}
      label="Empresa"
      itemLabel={"nome"}
      fullWidth
      onFetchAPI={handleFetchAPI}
      {...props}
    />
  );
};

export default EmpresaComplete;
