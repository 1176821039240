import { Avatar, Chip, Typography } from "@mui/material";
import React, { useState } from "react";
import PessoaService from "../../services/PessoaService";
import AutoCompleteAsyncPersist from "./autocomplete/AutoCompleteAsyncPersist";

const PessoaAutoComplete = (props) => {
  const [error, setError] = useState(false);

  const handleFetchAPI = async (valueSearch) => {
    if (valueSearch.length < 3) {
      setError(true);
      return [];
    }

    setError(false);

    const response = await PessoaService.pesquisar(
      null,
      null,
      valueSearch,
      null,
      null,
      "JURIDICA",
      0
    );

    if (response.content.length === 0) {
      setError(true);
    } else {
      setError(false);
    }

    return response.content;
  };

  const renderTags = (tagValue, getTagProps) => {
    return tagValue.map((option, index) => (
      <Chip
        color="secondary"
        avatar={<Avatar alt={option.nome} src={option.caminhoFoto} />}
        label={option.nome}
        {...getTagProps({ index })}
      />
    ));
  };

  return (
    <div>
      <AutoCompleteAsyncPersist
        name={props.name ? props.name : "autoPessoa"}
        renderTags={renderTags}
        label={props.label ? props.label : "Consultor"}
        itemLabel={"nomeRepresentante"}
        fullWidth
        onFetchAPI={handleFetchAPI}
        value={props.value || null}
        onChange={props.onChange}
        noOptionsText={
          error
            ? "Digite pelo menos 3 caracteres"
            : "Nenhum resultado encontrado!"
        }
      />
    </div>
  );
};

export default PessoaAutoComplete;
